import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i6 from '@taiga-ui/cdk';
import { TuiMonth, ALWAYS_FALSE_HANDLER, TUI_FIRST_DAY, TUI_LAST_DAY, TuiDay, tuiNullableSame, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { TUI_DEFAULT_MARKER_HANDLER } from '@taiga-ui/core/constants';
import * as i1 from '@taiga-ui/core/components/scrollbar';
import { TuiScrollbarModule } from '@taiga-ui/core/components/scrollbar';
import * as i2 from '@taiga-ui/core/components/primitive-year-picker';
import { TuiPrimitiveYearPickerModule } from '@taiga-ui/core/components/primitive-year-picker';
import * as i3 from '@taiga-ui/core/internal/primitive-year-month-pagination';
import { TuiPrimitiveYearMonthPaginationModule } from '@taiga-ui/core/internal/primitive-year-month-pagination';
import * as i4 from '@taiga-ui/core/components/primitive-calendar';
import { TuiPrimitiveCalendarModule } from '@taiga-ui/core/components/primitive-calendar';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
function TuiCalendarComponent_tui_scrollbar_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-scrollbar", 2)(1, "tui-primitive-year-picker", 3);
    i0.ɵɵlistener("yearClick", function TuiCalendarComponent_tui_scrollbar_0_Template_tui_primitive_year_picker_yearClick_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onPickerYearClick($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("initialItem", ctx_r1.month)("max", ctx_r1.computedMax)("min", ctx_r1.computedMin)("value", ctx_r1.value);
  }
}
function TuiCalendarComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-primitive-year-month-pagination", 4);
    i0.ɵɵlistener("valueChange", function TuiCalendarComponent_ng_template_1_Template_tui_primitive_year_month_pagination_valueChange_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onPaginationValueChange($event));
    })("yearClick", function TuiCalendarComponent_ng_template_1_Template_tui_primitive_year_month_pagination_yearClick_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onPaginationYearClick());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(1, "tui-primitive-calendar", 5);
    i0.ɵɵpipe(2, "tuiMapper");
    i0.ɵɵlistener("dayClick", function TuiCalendarComponent_ng_template_1_Template_tui_primitive_calendar_dayClick_1_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onDayClick($event));
    })("hoveredItemChange", function TuiCalendarComponent_ng_template_1_Template_tui_primitive_calendar_hoveredItemChange_1_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onHoveredItemChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("max", ctx_r1.computedMaxViewedMonth)("min", ctx_r1.computedMinViewedMonth)("value", ctx_r1.month);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabledItemHandler", i0.ɵɵpipeBind4(2, 9, ctx_r1.disabledItemHandler, ctx_r1.disabledItemHandlerMapper, ctx_r1.computedMin, ctx_r1.computedMax))("hoveredItem", ctx_r1.hoveredItem)("markerHandler", ctx_r1.markerHandler)("month", ctx_r1.month)("showAdjacent", ctx_r1.showAdjacent)("value", ctx_r1.value);
  }
}
class TuiCalendarComponent {
  constructor() {
    this.day = null;
    this.view = 'month';
    this.month = TuiMonth.currentLocal();
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.min = TUI_FIRST_DAY;
    this.max = TUI_LAST_DAY;
    this.minViewedMonth = TUI_FIRST_DAY;
    this.maxViewedMonth = TUI_LAST_DAY;
    this.hoveredItem = null;
    this.showAdjacent = true;
    this.markerHandler = TUI_DEFAULT_MARKER_HANDLER;
    this.dayClick = new EventEmitter();
    this.monthChange = new EventEmitter();
    this.hoveredItemChange = new EventEmitter();
    this.disabledItemHandlerMapper = (disabledItemHandler, min, max) => item => item.dayBefore(min) || item.dayAfter(max) || disabledItemHandler(item);
  }
  set initialView(view) {
    this.view = view;
  }
  set value(value) {
    this.day = value;
    if (this.showAdjacent && value instanceof TuiDay) {
      this.month = value;
    }
  }
  get value() {
    return this.day;
  }
  get isInYearView() {
    return this.view === 'year';
  }
  get computedMin() {
    var _a;
    return (_a = this.min) !== null && _a !== void 0 ? _a : TUI_FIRST_DAY;
  }
  get computedMax() {
    var _a;
    return (_a = this.max) !== null && _a !== void 0 ? _a : TUI_LAST_DAY;
  }
  get computedMinViewedMonth() {
    var _a;
    const min = this.computedMin;
    const minViewed = (_a = this.minViewedMonth) !== null && _a !== void 0 ? _a : TUI_FIRST_DAY;
    return minViewed.monthSameOrAfter(min) ? minViewed : min;
  }
  get computedMaxViewedMonth() {
    var _a;
    const max = this.computedMax;
    const maxViewed = (_a = this.maxViewedMonth) !== null && _a !== void 0 ? _a : TUI_LAST_DAY;
    return maxViewed.monthSameOrBefore(max) ? maxViewed : max;
  }
  onPaginationYearClick() {
    this.view = 'year';
  }
  onPickerYearClick({
    year
  }) {
    this.view = 'month';
    this.updateViewedMonth(new TuiMonth(year, this.month.month));
  }
  onPaginationValueChange(month) {
    this.updateViewedMonth(month);
  }
  onDayClick(day) {
    this.dayClick.emit(day);
  }
  onHoveredItemChange(day) {
    this.updateHoveredDay(day);
  }
  updateViewedMonth(month) {
    if (this.month.monthSame(month)) {
      return;
    }
    this.month = month;
    this.monthChange.emit(month);
  }
  updateHoveredDay(day) {
    if (tuiNullableSame(this.hoveredItem, day, (a, b) => a.daySame(b))) {
      return;
    }
    this.hoveredItem = day;
    this.hoveredItemChange.emit(day);
  }
}
TuiCalendarComponent.ɵfac = function TuiCalendarComponent_Factory(t) {
  return new (t || TuiCalendarComponent)();
};
TuiCalendarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiCalendarComponent,
  selectors: [["tui-calendar"]],
  inputs: {
    initialView: "initialView",
    month: "month",
    disabledItemHandler: "disabledItemHandler",
    min: "min",
    max: "max",
    minViewedMonth: "minViewedMonth",
    maxViewedMonth: "maxViewedMonth",
    hoveredItem: "hoveredItem",
    showAdjacent: "showAdjacent",
    markerHandler: "markerHandler",
    value: "value"
  },
  outputs: {
    dayClick: "dayClick",
    monthChange: "monthChange",
    hoveredItemChange: "hoveredItemChange"
  },
  decls: 3,
  vars: 2,
  consts: [["calendar", ""], ["automation-id", "tui-calendar__scrollbar", "class", "t-scrollbar", 4, "ngIf", "ngIfElse"], ["automation-id", "tui-calendar__scrollbar", 1, "t-scrollbar"], ["automation-id", "tui-calendar__year", 3, "yearClick", "initialItem", "max", "min", "value"], ["automation-id", "tui-calendar__pagination", 1, "t-pagination", 3, "valueChange", "yearClick", "max", "min", "value"], ["automation-id", "tui-calendar__calendar", 3, "dayClick", "hoveredItemChange", "disabledItemHandler", "hoveredItem", "markerHandler", "month", "showAdjacent", "value"]],
  template: function TuiCalendarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiCalendarComponent_tui_scrollbar_0_Template, 2, 4, "tui-scrollbar", 1)(1, TuiCalendarComponent_ng_template_1_Template, 3, 14, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const calendar_r4 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.isInYearView)("ngIfElse", calendar_r4);
    }
  },
  dependencies: [i1.TuiScrollbarComponent, i2.TuiPrimitiveYearPickerComponent, i3.TuiPrimitiveYearMonthPaginationComponent, i4.TuiPrimitiveCalendarComponent, i5.NgIf, i6.TuiMapperPipe],
  styles: ["[_nghost-%COMP%]{display:block;height:18.25rem;width:15.75rem;padding:1rem 1.125rem;box-sizing:content-box}.t-scrollbar[_ngcontent-%COMP%]{height:18.25rem;width:16.875rem}.t-pagination[_ngcontent-%COMP%]{margin-bottom:1rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarComponent, [{
    type: Component,
    args: [{
      selector: 'tui-calendar',
      templateUrl: './calendar.template.html',
      styleUrls: ['./calendar.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    initialView: [{
      type: Input
    }],
    month: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    minViewedMonth: [{
      type: Input
    }],
    maxViewedMonth: [{
      type: Input
    }],
    hoveredItem: [{
      type: Input
    }],
    showAdjacent: [{
      type: Input
    }],
    markerHandler: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    dayClick: [{
      type: Output
    }],
    monthChange: [{
      type: Output
    }],
    hoveredItemChange: [{
      type: Output
    }]
  });
})();
class TuiCalendarModule {}
TuiCalendarModule.ɵfac = function TuiCalendarModule_Factory(t) {
  return new (t || TuiCalendarModule)();
};
TuiCalendarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCalendarModule
});
TuiCalendarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiPrimitiveYearMonthPaginationModule, TuiPrimitiveCalendarModule, TuiPrimitiveYearPickerModule, TuiScrollbarModule, TuiMapperPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCalendarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiPrimitiveYearMonthPaginationModule, TuiPrimitiveCalendarModule, TuiPrimitiveYearPickerModule, TuiScrollbarModule, TuiMapperPipeModule],
      declarations: [TuiCalendarComponent],
      exports: [TuiCalendarComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCalendarComponent, TuiCalendarModule };
