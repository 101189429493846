import * as i0 from '@angular/core';
import { EventEmitter, ElementRef, Component, ChangeDetectionStrategy, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import { AbstractTuiInteractive, tuiIsNativeFocusedIn } from '@taiga-ui/cdk';
import { TUI_SPIN_ICONS, TUI_SPIN_TEXTS } from '@taiga-ui/core/tokens';
import * as i1 from '@taiga-ui/core/components/button';
import { TuiButtonModule } from '@taiga-ui/core/components/button';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from 'rxjs';
const _c0 = ["*"];
function TuiPrimitiveSpinButtonComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 1);
    i0.ɵɵlistener("click", function TuiPrimitiveSpinButtonComponent_ng_container_0_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onLeftClick());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "span", 2);
    i0.ɵɵprojection(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 3);
    i0.ɵɵlistener("click", function TuiPrimitiveSpinButtonComponent_ng_container_0_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onRightClick());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r3 = ctx.ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("t-hidden", ctx_r1.leftComputedDisabled);
    i0.ɵɵproperty("focusable", ctx_r1.computedFocusable)("icon", ctx_r1.icons.decrement)("title", texts_r3[0]);
    i0.ɵɵadvance(3);
    i0.ɵɵclassProp("t-hidden", ctx_r1.rightComputedDisabled);
    i0.ɵɵproperty("focusable", ctx_r1.computedFocusable)("icon", ctx_r1.icons.increment)("title", texts_r3[1]);
  }
}
class TuiPrimitiveSpinButtonComponent extends AbstractTuiInteractive {
  constructor(el, icons, spinTexts$) {
    super();
    this.el = el;
    this.icons = icons;
    this.spinTexts$ = spinTexts$;
    this.disabled = false;
    this.leftDisabled = false;
    this.rightDisabled = false;
    this.leftClick = new EventEmitter();
    this.rightClick = new EventEmitter();
  }
  get focused() {
    return tuiIsNativeFocusedIn(this.el.nativeElement);
  }
  get leftComputedDisabled() {
    return this.computedDisabled || this.leftDisabled;
  }
  get rightComputedDisabled() {
    return this.computedDisabled || this.rightDisabled;
  }
  onLeftClick() {
    if (!this.leftComputedDisabled) {
      this.leftClick.emit();
    }
  }
  onRightClick() {
    if (!this.rightComputedDisabled) {
      this.rightClick.emit();
    }
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  onFocusVisible(focusVisible) {
    this.updateFocusVisible(focusVisible);
  }
}
TuiPrimitiveSpinButtonComponent.ɵfac = function TuiPrimitiveSpinButtonComponent_Factory(t) {
  return new (t || TuiPrimitiveSpinButtonComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_SPIN_ICONS), i0.ɵɵdirectiveInject(TUI_SPIN_TEXTS));
};
TuiPrimitiveSpinButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPrimitiveSpinButtonComponent,
  selectors: [["tui-primitive-spin-button"]],
  hostBindings: function TuiPrimitiveSpinButtonComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("mousedown.silent.prevent", function TuiPrimitiveSpinButtonComponent_mousedown_silent_prevent_HostBindingHandler() {
        return 0;
      })("keydown.arrowLeft.prevent", function TuiPrimitiveSpinButtonComponent_keydown_arrowLeft_prevent_HostBindingHandler() {
        return ctx.onLeftClick();
      })("keydown.arrowRight.prevent", function TuiPrimitiveSpinButtonComponent_keydown_arrowRight_prevent_HostBindingHandler() {
        return ctx.onRightClick();
      })("focusin", function TuiPrimitiveSpinButtonComponent_focusin_HostBindingHandler() {
        return ctx.onFocused(true);
      })("focusout", function TuiPrimitiveSpinButtonComponent_focusout_HostBindingHandler() {
        return ctx.onFocused(false);
      });
    }
  },
  inputs: {
    disabled: "disabled",
    leftDisabled: "leftDisabled",
    rightDisabled: "rightDisabled"
  },
  outputs: {
    leftClick: "leftClick",
    rightClick: "rightClick"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 2,
  vars: 3,
  consts: [[4, "ngIf"], ["appearance", "flat", "automation-id", "tui-primitive-spin-button__left", "size", "xs", "tuiIconButton", "", "type", "button", 3, "click", "focusable", "icon", "title"], [1, "t-content", "t-calendar-title"], ["appearance", "flat", "automation-id", "tui-primitive-spin-button__right", "size", "xs", "tuiIconButton", "", "type", "button", 3, "click", "focusable", "icon", "title"]],
  template: function TuiPrimitiveSpinButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiPrimitiveSpinButtonComponent_ng_container_0_Template, 5, 10, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.spinTexts$));
    }
  },
  dependencies: [i1.TuiButtonComponent, i2.NgIf, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:flex;align-items:center;justify-content:space-between;font:var(--tui-font-text-l);text-align:center;font-weight:bold}.t-hidden[_ngcontent-%COMP%]{visibility:hidden}.t-content[_ngcontent-%COMP%]{padding:0 .5rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveSpinButtonComponent, [{
    type: Component,
    args: [{
      selector: 'tui-primitive-spin-button',
      templateUrl: './primitive-spin-button.template.html',
      styleUrls: ['./primitive-spin-button.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '(mousedown.silent.prevent)': '(0)'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SPIN_ICONS]
      }]
    }, {
      type: i3.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_SPIN_TEXTS]
      }]
    }];
  }, {
    disabled: [{
      type: Input
    }],
    leftDisabled: [{
      type: Input
    }],
    rightDisabled: [{
      type: Input
    }],
    leftClick: [{
      type: Output
    }],
    rightClick: [{
      type: Output
    }],
    onLeftClick: [{
      type: HostListener,
      args: ['keydown.arrowLeft.prevent']
    }],
    onRightClick: [{
      type: HostListener,
      args: ['keydown.arrowRight.prevent']
    }],
    onFocused: [{
      type: HostListener,
      args: ['focusin', ['true']]
    }, {
      type: HostListener,
      args: ['focusout', ['false']]
    }]
  });
})();
class TuiPrimitiveSpinButtonModule {}
TuiPrimitiveSpinButtonModule.ɵfac = function TuiPrimitiveSpinButtonModule_Factory(t) {
  return new (t || TuiPrimitiveSpinButtonModule)();
};
TuiPrimitiveSpinButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPrimitiveSpinButtonModule
});
TuiPrimitiveSpinButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiButtonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveSpinButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiButtonModule],
      declarations: [TuiPrimitiveSpinButtonComponent],
      exports: [TuiPrimitiveSpinButtonComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPrimitiveSpinButtonComponent, TuiPrimitiveSpinButtonModule };
